.bg-img{
    background: url('../../../assets/Icons/bg.png');
    background-position: bottom;
}
.bg-img2{
    background: url('../../../assets/Icons/bg-img.png');
}
.nav-bar > li:hover{
    border-bottom: 2px solid white;
}
.f-city > li:hover{
    border-bottom: 2px solid #000000f2;
    color: #000000f2;
}
.sf-list > li:hover{
    /* border-bottom: 2px solid #000000f2; */
    color: #000000f2;
    /* font-weight: bolder !important; */
}
.tab:hover > hr{
    color: #1b1c1d !important;
}
.d-tab:hover > span{
    border-bottom: 2px solid #1b1c1d !important;
    color: #1b1c1d !important;
}
.route-card:before {
    content:'';
    position:absolute;
    width:100%;
    height:30px;
    background:linear-gradient(to left, #c4268c, #9a0b72);
    top:-30px;
    left:0;
}