.right-section{
	border-left: 30px solid #000000;
    border-radius: 1000px 0px 0px 1000px;
    position: absolute;
    width: 65vw;
    top: -260px;
    height: 175vh;
    padding-right: 325px;
    padding-top: 245px;
}
.bg-png-fixed{
    position: fixed;
    right: 0;
    height: 724px;
    width: 30%;
}
.logo-fixed{
    position: fixed;
    z-index: 10;
    top: -5px;
    width: 210px;
    right: 0;
}
.plane-img-fixed{
    position: fixed;
    z-index: 10;
    top: 140px;
    right: -89px;
    width: 45%;
}
.profile-pic{
    position: absolute;
    z-index: 10;
    left: -100px;
    width: 65px;
    height: 65px;
    top: 15px;
}
.position-relative{
    position: relative;
}
.nav-bar{
    display: inline-flex;
    float: right;
    margin-right: 100px;
    padding-top: 25px;
    li{
        list-style: none;
        border-bottom: 2px solid #1b1c1d;
        margin: 0px 18px;
        a{
            color: white;
            font-size: 20px;
            text-decoration: none;
            text-transform: uppercase;
            padding: 5px 0px;
        }
        
    }
    .actives{
        border-bottom: 2px solid white;
    }
}
.li-border-bottom-0{
    li{
        padding-bottom: 10px;
        border-bottom: 0px solid #000000f2 !important;
    }
}
.my-card{
    .tab{
        padding-top: 15px;
        cursor: pointer;
        // padding-right: 45px;
        hr{
            color:white;
            margin: 0rem 0;
            opacity: 1;
            height: 2px;
            width: 55%;
        }
        p{
            font-size: 17px;
            padding: 0px 0px 0px 0px;
            margin-right: 5px;
            line-height: 1px;
        }
        .hr{
            color: #000000f2 !important;
        }
    }
}
.pdfr{
    h2{
        font-size: 1.6rem;
    }
    margin-top: -70px;
    .prev{
        float: left;
        height: 45px;
        width: 45px;
        padding: 0px 16px;
        cursor: pointer;
    }
    .next{
        float: right;
        height: 45px;
        width: 45px;
        padding: 0px 14px;
        cursor: pointer;
    }
    .disabled{
        background-color: #1b1c1d !important;
    }
    .f-city{
        display: inline-flex;
        margin-left: 0px;
        padding-top: 10px;
        li{
            color: #747474;
            text-transform: uppercase;
            font-weight: bolder;
            font-size: 16px;
            list-style: none;
            border-bottom: 2px solid white;
            margin-right: 50px;
            cursor: pointer;
            
            
        }
        .active{
            border-bottom: 2px solid black;
            color: black;
        }
    }
    .route-card{
        width: 300px !important;
        // border-top: 10px solid red;
        border-radius: 10px;
        .border-gradient{
            position: absolute;
            top: 0;
            width: 100%;
            background: linear-gradient(45deg, #f8f9fa -15%, #000000f2 70%) !important;
            border-radius: 10px 10px 0px 0px;
            height: 10px;
        }
    }
    .pdfr-slider{
        z-index: 1;
        width: unset;
        button{
            display: none !important;
        }
        .rupee-icon{
            float: left;
        }
        .slick-list{
            .slick-track{
                padding-left: 225px;
                //transform: translate3d(-1416px, 0px, 0px);
                .slick-slide{
                    width: 360px !important;
                }
            }
        }
    }
}
.s-offer{
    h2{
        font-size: 1.6rem;
    }
    margin-top: 0px;
    .prev{
        float: right;
        height: 30px;
        width: 30px;
        margin-right: 10px;
        cursor: pointer;
        padding: 0px 9px !important;
    }
    .next{
        float: right;
        height: 30px;
        width: 30px;
        cursor: pointer;
        padding: 0px 6px !important;
    }
    .disabled{
        background-color: #1b1c1d !important;
    }
    .sf-list{
        display: inline-flex;
        margin-left: 0px;
        padding-top: 0px;
        li{
            color: #747474;
            text-transform: uppercase;
            font-weight: 300;
            font-size: 16px;
            list-style: none;
            border-bottom: 1px solid #dee2e6;
            // margin-right: 50px;
            padding-right:30px;
            padding-bottom: 10px;
            cursor: pointer;
            
            
        }
        .more{
            font-weight: bolder !important;
        }
        .active{
            // border-bottom: 2px solid black;
            color: black;
            font-weight: bolder !important;
        }
        
    }
    .n-img{
        top: 15px;
    }
    .n-text{
        top: 120px;
    }
    .btn-footer{
        bottom: -8px;
        right: 0;
        padding: 5px 25px;
    }
    .s-offer-slider{
        //width: 100vw !important;
        .slick-prev{
            display: none !important;
        }
        .slick-next{
            display: none !important;
        }
        .slick-list{
            .slick-track{
                height: 100px;
                padding-left: 25px;
                //transform: translate3d(-1416px, 0px, 0px);
                // .slick-slide{
                //     width: 400px !important;
                // }
            }
        }
    }
}
.travel-story{
    h2{
        font-size: 1.6rem;
    }
    margin-top: 0px;
    .prev{
        height: 30px;
        width: 30px;
        top: 50%;
        left: 25px;
        margin-right: 10px;
        cursor: pointer;
        padding: 0px 9px !important;
        z-index: 10;
    }
    .next{
        height: 30px;
        width: 30px;
        top: 50%;
        right: 25px;
        margin-right: 10px;
        cursor: pointer;
        padding: 0px 6px !important;
        z-index: 10;
    }
    .disabled{
        background-color: #1b1c1d !important;
    }
    .w-h{
        width: 235px !important;
        height: 310px !important;
    }
    .mr{
        margin-right: 60px;
    }
    .btn-out-let{
        top: 20px;
        left: 10px;
        color: white;
        border: 1px solid white;
        border-radius: 20px;
        padding: 2px 15px;
    }
    .txt-footer{
        bottom: 35px;
        left: 0px;
        color: white;
        padding: 2px 20px;
        font-size: 17px;
    }
    .read-more-footer{
        bottom: 10px;
        right: 20px;
        color: white;
        font-size: 12px;
    }
    .ts-img{
        width: 235px !important;
        height: 310px !important;
        opacity: 0.8 !important;
    }
}

.one-way-fare-calender-slider{
    .slick-arrow{
        width: 60px;
        height: 100%;
        z-index: 10;
        background-color: white;
    }
    .slick-prev::before{
        //display: none !important;
        font-size: 40px !important;
        opacity: 1 !important;
        color: #000000f2!important;
        z-index: 10;
    }
    .slick-next::before{
        //display: none !important;
        font-size: 40px !important;
        opacity: 1 !important;
        color: #000000f2!important;
        z-index: 10;
    }

    .slick-list{
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.testimonials{
    h2{
        font-size: 1.6rem;
    }
    .h2{
        b{
            color: #0F3B50 !important;
        }
    }
    .testimonial-slider{
        //width: 100vw !important;
        .slick-arrow{
            width: 38px;
            height: 38px;
        }
        .slick-prev::before{
            //display: none !important;
            font-size: 40px !important;
            opacity: 1 !important;
            color: #000000f2!important;
        }
        .slick-next::before{
            //display: none !important;
            font-size: 40px !important;
            opacity: 1 !important;
            color: #000000f2!important;
        }
        .slick-list{
            .slick-track{
                //height: 100px;
                padding-top: 5px;
                //transform: translate3d(-1416px, 0px, 0px);
                // .slick-slide{
                //     width: 400px !important;
                // }
            }
        }
    }
    margin-top: 0px;
    .prev{
        height: 40px;
        width: 40px;
        top: 60%;
        left: 50px;
        margin-right: 10px;
        cursor: pointer;
        padding: 0px 13px !important;
        z-index: 10;
        svg{
            color:black;
        }
    }
    .next{
        height: 40px;
        width: 40px;
        top: 60%;
        right: 50px;
        margin-right: 10px;
        cursor: pointer;
        padding: 0px 6px !important;
        z-index: 10;
        svg{
            color:black;
        }
    }
    .c{
        margin-left: 70px;
        margin-right: 70px;
        width: 1000px;
    }
    .disabled{
        background-color: #1b1c1d !important;
    }
    .w-h{
        width: 235px !important;
        height: 250px !important;
    }
    .mr{
        margin-right: 60px;
    }
    .pl{
        border-radius: 50px;
        width: 50px;
        height: 50px;
        max-width: 50px;
    }
    .s-p{
        font-size: 12px;
    }
    .sb-p{
        font-size: 10px;
    }
    .sm-p{
        font-size: 7px;
    }
    .sx-p{
        font-size: 6px;
    }
    .footer-block{
        position: absolute;
        bottom: 0;
        left: 0;
        height: 10px;
        width: 100%;
        background: linear-gradient( 0deg, #000000f2 20%, #1b1c1d 90%) !important;
    }
    .mx-5x{
        margin-left: 3.5rem;
        margin-right: 3.5rem;
    }
    .description{
        .d-tab{
            cursor: pointer;
            span{
                font-size: 16.5px;
                text-transform: uppercase;
                font-weight: bold;
                border-bottom: 2px solid white;
            }
        }
        .active{
            span{
                border-bottom: 2px solid #1b1c1d;
                color: #1b1c1d;
            }
        }
    }
    .footer-palne{
        width: 600px;
        position: absolute;
        bottom: 680px;
        right: -145px;
    }
    
}
.h2 {
    color: #4C4C4C !important;
}
.w-390-px{
    width: 350px;
    max-width: 350px;
    min-width: 350px;
}

.stay-up-to-date{
    .girl-plane{
        width: 90%;
    }
    .form-btn{
        text-transform: uppercase;
        font-size: 17px;
        padding: 7px 30px;
        margin-top: 2px;
    }
    .play-store{
        width: 145px;
    }
    .apple-store{
        width: 145px;
    }
}
.footer{
    .list{
        list-style: none;
        display: flex;
        margin: auto;
        li{
            margin-left: 10px;
            margin-right: 10px;
            padding-left: 10px;
            padding-right: 10px;
            text-transform: uppercase;
            font-weight: bolder;
        }
    }
    .of-img{
        width: 160px;
        height: 60px;
    }
    .f-img{
        width: 75%;
    }
    .footer-img{
        width: 100%;
        margin-top: 55px;
        border-bottom: 1px solid #dee2e6;
    }
}
.header{
    height: 83px;
    .left-logo-section{
        top: -100px;
        left: -120px;
        width: 400px;
        height: 168px;
        border-radius: 0px 0px 200px 200px;
        .f-img{
            width: 155px;
            bottom: 8px;
            right: 105px;
        }
        
    }
    .h-right-section{
        .profile-logo-dropdown{
            left: 0;
            float: right;
        }
        .login-btn {
            left: 0;
            float: right;
            background-color: #272727 !important;
            color: white !important;
        }
    }

    
}
.TravelStoryDetails{
    .p{
        font-size: 20px;
    }
    .banner{
        height: 430px;
    }
}
.form{
    .t-color{
        color: #7E7E7E !important;
    }
}
.LiBlock{
    .ul{
        padding: 0;
        margin: 0;
        li{
            list-style: none;
        }
    }
}
.vertical-calendar{
    .p{
        font-size: 18px;
    }
}
.free-buggage{
    table{
        color: #686868 !important;
        tbody{
            tr{
                td{
                    ul{
                        li{
                            color: #686868 !important;
                            font-size: 13px !important;
                        }
                    }
                }
            }
        }
    }
}
.FareDetailList{
    .list-group-item {
        padding: 10px;
    }
    .MuiTreeItem-content{
        .MuiTreeItem-iconContainer{
            svg{
                width: 30px;
                height: 30px;
            }
        }
    }
    .MuiTreeItem-group{
        padding: 5px 5px !important;
        padding-right: 10px !important;
    }
    .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {
        background-color: white !important;
    }
    .MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label {
        background-color: white !important;
    }
}
.TreeViews{
    .list-group-item {
        padding: 10px;
    }
    .MuiTreeItem-content{
        .MuiTreeItem-iconContainer{
            svg{
                width: 30px;
                height: 30px;
            }
        }
    }
    .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {
        background-color: white !important;
    }
    .MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label {
        background-color: white !important;
    }
}
.fare-rules{
    .table{
        thead{
            tr{
                th{
                    font-size: 19px;
                    padding: 10px;
                }
            }
        }
        tbody{
            tr{
                td{
                    padding: 10px;
                }
            }
        }
    }
    .fee{
        color: #7B73EF;
        font-weight: bold;
    }
    table {
        tbody {
            tr {
                td {
                    ul {
                        li {
                            line-height: 12px;
                            padding: 3px 0px;
                            color: #686868 !important;
                            font-size: 10px !important;
                        }
                    }
                }
            }
        }
    }
}
.TravellerDetails{
    .TravellerDetails-TreeViews{
        .list-group-item {
            padding: 0px;
        }
        .MuiTreeItem-content{
            .MuiTreeItem-iconContainer{
                svg{
                    width: 30px;
                    height: 30px;
                }
            }
        }
        .MuiTreeItem-content{
            background-color: #F5F5F5 !important;
            padding: 10px;
        }
        .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {
            background-color: #F5F5F5 !important;
        }
        .MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label {
            background-color: #F5F5F5 !important;
        } 
    }
}

.footer-flight-details-treeViews{
    .list-group-item {
        padding: 0px;
    }
    .MuiTreeItem-content{
        .MuiTreeItem-iconContainer{
            svg{
                width: 30px;
                height: 30px;
            }
        }
    }
    .MuiTreeItem-group {
        margin: 0;
        padding: 0;
        margin-left: 0px !important;
    }
    .MuiTreeItem-content{
        background-color: #F5F5F5 !important;
        padding: 10px;
    }
    .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {
        background-color: #F5F5F5 !important;
    }
    .MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label {
        background-color: #F5F5F5 !important;
    } 
}

.apply-promocode{
    input{
        padding-right: 50px !important;
    }
}

.paymentgetway-tab{
    .ui{
        .column{
            .menu{
                .item{
                    background: #F4F4F6;
                    border-left: 0px solid transparent;
                    border-bottom: 1px solid gray;
                    border-top: 0px solid gray;
                    border-right: none;
                    border-radius: 0px;
                }
                .active{
                    border-left: 0px solid white;
                }
            }
        }
        .stretched{
            .tab{
                border: 0px solid #d4d4d5;
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
    }
}
.my-search-dropdown{
    .transition{
        height: 118px;
    }
}
.fromDropdown{
    top: 434px;
    left: 58px;
    .divider{
        width: 100% !important;
    }
}
.toDropdown{
    top: 434px;
    left: 200px;
}

.travellers{
    top: 425px;
    left: 536px;
    z-index: 10;
}

.TravellersDropdown{
    width: 250px;
    border: 1px solid #96c8da;
    box-shadow: 0 2px 3px 0 rgb(34 36 38 / 15%);
    background-color: white;
    .sm-input{
        width: 50px;
        height: 30px;
        input{
            text-align: center;
        }
    }
}



.VarticalSearchCard{
    .fromDropdown{
        top: 270px;
        left: 58px;
        .divider{
            width: 100% !important;
        }
    }
    .toDropdown{
        top: 270px;
        left: 240px;
    }
    
    .travellers{
        top: 270px;
        left: 800px;
        z-index: 10;
    }
}

.header-login-btn{
    position: absolute;
    z-index: 10;
    left: -100px;
    width: 65px;
    height: 65px;
    top: 15px;
}


.search-card-section{
    background: unset !important;
    position: absolute !important;
    top: 0 !important;
}


// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 

    .search-card-section{
        background: linear-gradient( -10deg, #f8f9fa -15%, #000000f2 80%) !important;
        position: unset !important;
        top: unset !important;
    }
    .pdfr {
        .route-card {
            width: 308px !important;
            border-radius: 10px;
        }
    }

    .pdfr {
        .pdfr-slider {
            .slick-list {
                .slick-track {
                    .slick-slide {
                        width: 339px !important;
                    }
                }
            }
        }
    }
 }

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) { 
    
 }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
    
 }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) { 
    .testimonials{
        .footer-palne{
            width: 600px;
            position: unset !important;
            bottom: 680px;
            right: -145px;
        }
    }
 }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (min-width: 1599.98px) { 
    .testimonials{
        .footer-palne{
            width: 600px;
            position: unset !important;
            bottom: 680px;
            right: -145px;
        }
    }
}

@media (min-width: 1600px) {
    .pdfr{
        width: unset !important;
        .pdfr-slider{
            width: unset !important;
        }
    }
    .pdfr .pdfr-slider .slick-list .slick-track {
        padding-left: 200px;
    }

    .testimonials{
        .footer-palne{
            width: 600px;
            position: unset !important;
            bottom: 680px;
            right: -145px;
        }
    }
}