// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/mixins";

// 4. Include any optional Bootstrap components as you like
@import "../../../../node_modules/bootstrap/scss/root";
@import "../../../../node_modules/bootstrap/scss/reboot";
@import "../../../../node_modules/bootstrap/scss/type";
@import "../../../../node_modules/bootstrap/scss/images";
@import "../../../../node_modules/bootstrap/scss/containers";
@import "../../../../node_modules/bootstrap/scss/grid";

@import "variables";
// @import "../../../../node_modules/bootstrap/scss/variables";
// @import "../../../../node_modules/animate.css/animate";

///////// Tables //////////
.react-bootstrap-table {
    table {
      overflow-x: auto;
      width: 100%;
    }
  }
  .widget-table-overflow {
    width: 100%;
    margin: 0 0 24px;
  
    th:first-child,
    td:first-child {
      padding-left: 8px;
    }
  
    th:last-child,
    td:last-child {
      padding-right: 24px;
    }
  
    .form-check-input {
      position: relative;
    }
  }
  
  .table-bordered th {
    background-color: $gray-300;
  }
  
  table > thead > tr > th {
    border-top: none !important;
    border-bottom-width: 1px !important;
    text-transform: uppercase;
  }
  
  .table-striped {
    thead {
      th {
        vertical-align: middle;
        border: none;
      }
    }
    tbody {
      tr:nth-of-type(odd) {
        background-color: $base-light-gray;
      }
      td {
        border: none;
      }
    }
  }
  
//   .table-hover {
//     tbody tr {
//       & :hover {
//         background-color: $violet !important;
//         td {
//           color: white;
//         }
//       }
//     }
//   }
  
  .pagination {
    justify-content: center;
    align-items: center;
  }
  
  .pagination-with-border {
  
  }
  
  .page-link {
    padding: 4px 12px;
    border: $pagination-border-width solid $pagination-border-color;
  
    &:hover {
      background-color: transparent;
      border-color: $pagination-hover-border-color;
    }
  
    &:focus {
      z-index: 3;
      outline: none;
      box-shadow: none;
    }
  }
  
  .page-item {
    margin-left: 2px;
    margin-right: 2px;
    &:first-child {
      .page-link {
        border: none;
        margin-right: 80px;
        font-size: 36px;
        line-height: 1;
  
        @media (max-width: breakpoint-max(md)) {
          margin-right: 8px;
        }
      }
    }
    &:last-child {
      .page-link {
        margin-left: 80px;
        border: none;
        font-size: 36px;
        line-height: 1;
  
        @media (max-width: breakpoint-max(md)) {
          margin-left: 8px;
        }
      }
    }
    @media (max-width: breakpoint-max(md)) {
      margin-left: 4px;
      margin-right: 4px;
    }
  }
  
  .pagination-borderless {
    .page-item {
      .page-link {
        border: none;
        color: $pagination-disabled-color;
      }
      &.active .page-link {
        z-index: 3;
        color: $violet;
        background-color: transparent;
        border: none;
      }
  
      &:first-child {
        .page-link {
          color: $violet;
        }
      }
      &:last-child {
        .page-link {
          color: $violet;
        }
      }
      &.disabled .page-link {
        color: $pagination-disabled-color;
        pointer-events: none;
        cursor: auto;
        background-color: $pagination-disabled-bg;
        border-color: $pagination-disabled-border-color;
      }
    }
  }


  ///////// Badges //////////
.badge {
    padding: 4px 8px;
    font-size: $font-size-sm;
    font-weight: $font-weight-semi-bold;
    color: $white;
    border-radius: 4px;
}


.checkbox {
    padding-left: 20px;
    display: flex;
    margin-bottom: 4px;
}

.badge {
    padding: 4px 8px;
    font-size: 0.875rem;
    font-weight: 600;
    color: #fff;
    border-radius: 4px;
}

.badge-secondary-cyan {
    color: #212529;
    background-color: #41D5E2;
}

.badge-secondary-yellow {
    color: #212529;
    background-color: #FFC405;
}

.badge-secondary-red {
    color: #fff;
    background-color: #FF5668;
}

.CONFIRMED{
    color: #fff;
    background-color: #28a745;
}

.REJECTED{
  color: #fff;
  background-color: #dc3545;
}

.CANCELLED{
  color: #fff;
  background-color: #dc3545;
}

.FAILED{
  color: #fff;
  background-color: #dc3545;
}

.HOLD{
  color: white;
  background-color: #ffc107;
}

.INITIATED{
    color: #fff;
    background-color: #17a2b8;
}

.DR{
    color: red;
}

.CR{
    color: green;
}
.profile-dropdown{
  a{
    text-decoration: none !important;
  }
}

// .custom-menu{
//     .dropdown-menu{
//         background-color: #f8f9fa !important;
//         .dropdown-item{
//             background-color: #f8f9fa !important;
//             div{
//                 background-color: #f8f9fa !important;
//             }
//         }
//     }
// }