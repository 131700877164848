.Login1{
    background-color: red;
}
.logo{
    width: 300px;
}
.bg-plane{
    height: 30vh;
    background: url('../../../assets/Icons/plane.png');
}
.bg-img{
    background: url('../../../assets/Icons/bg.png');
}
.h2{
    color: #E9ECFF !important;
}
.plane-img{
    width: 600px;
    margin-top: -30px;
    margin-left: -145px;
}
.mt-n3{
    margin-top: -70px !important;
}
.mini-heading{
    font-size: 23px;
    font-weight: 300;
    color: #c6c7e6 !important;
}