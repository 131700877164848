.right-section{
	border-left: 30px solid #000000f2;
    border-radius: 1000px 0px 0px 1000px;
    position: absolute;
    width: 65vw;
    top: -260px;
    height: 175vh;
    padding-right: 325px;
    padding-top: 245px;
}
.bg-png-fixed{
    position: fixed;
    right: 0;
    height: 570px;
    width: 30%;
}
.logo-fixed{
    position: fixed;
    z-index: 10;
    top: -5px;
    width: 155px;
    // width: 210px;
    right: 0;
}
.plane-img-fixed{
    position: fixed;
    z-index: 10;
    top: 140px;
    right: -89px;
    width: 40%;
}
.profile-pic{
    position: absolute;
    z-index: 10;
    left: -100px;
    width: 65px;
    height: 65px;
    top: 15px;
}
.position-relative{
    position: relative;
}
.nav-bar{
    display: inline-flex;
    float: right;
    margin-right: 100px;
    padding-top: 25px;
    li{
        list-style: none;
        border-bottom: 2px solid #00000000;
        margin: 0px 18px;
        padding-bottom: 5px;
        //border-width: 0;
        a{
            color: white;
            font-size: 20px;
            text-decoration: none;
            text-transform: uppercase;
            padding: 0px 0px;
        }
        
    }
    .actives{
        border-bottom: 2px solid white;
        //border-width: 0;
    }
}
.my-card{
    .tab{
        padding-top: 15px;
        cursor: pointer;
        // padding-right: 45px;
        hr{
            color:white;
            margin: 0rem 0;
            opacity: 1;
            height: 2px;
            width: 55%;
        }
        p{
            font-size: 17px;
            padding: 0px 0px 0px 0px;
            margin-right: 5px;
            line-height: 1px;
        }
        .hr{
            color: #000000 !important;
        }
    }
}
.pdfr{
    h2{
        font-size: 1.6rem;
    }
    margin-top: -70px;
    .prev{
        float: left;
        height: 45px;
        width: 45px;
        padding: 0px 16px;
        cursor: pointer;
    }
    .next{
        float: right;
        height: 45px;
        width: 45px;
        padding: 0px 14px;
        cursor: pointer;
    }
    .disabled{
        background-color: #000000f2 !important;
    }
    .f-city{
        display: inline-flex;
        margin-left: 0px;
        padding-top: 30px;
        li{
            color: #747474;
            text-transform: uppercase;
            font-weight: bolder;
            font-size: 16px;
            list-style: none;
            border-bottom: 2px solid white;
            margin-right: 50px;
            cursor: pointer;
            
            
        }
        .active{
            border-bottom: 2px solid #000000f2;
            color: #000000f2;
        }
    }
    .route-card{
        width: 300px;
        // border-top: 10px solid red;
        border-radius: 10px;
        .border-gradient{
            position: absolute;
            top: 0;
            width: 100%;
            background: linear-gradient(45deg, #f8f9fa -15%, #000000f2 80%) !important;
            border-radius: 10px 10px 0px 0px;
            height: 10px;
        }
    }
}
.s-offer{
    h2{
        font-size: 1.6rem;
    }
    margin-top: 0px;
    .prev{
        float: right;
        height: 30px;
        width: 30px;
        margin-right: 10px;
        cursor: pointer;
        padding: 0px 9px !important;
    }
    .next{
        float: right;
        height: 30px;
        width: 30px;
        cursor: pointer;
        padding: 0px 6px !important;
    }
    .disabled{
        background-color: #000000f2 !important;
    }
    .sf-list{
        display: inline-flex;
        margin-left: 0px;
        padding-top: 0px;
        li{
            color: #747474;
            text-transform: uppercase;
            font-weight: 300;
            font-size: 16px;
            list-style: none;
            border-bottom: 1px solid #dee2e6;
            // margin-right: 50px;
            padding-right:30px;
            padding-bottom: 10px;
            cursor: pointer;
            
            
        }
        .more{
            font-weight: bolder !important;
        }
        .active{
            // border-bottom: 2px solid #000000f2;
            color: #000000f2;
            font-weight: bolder !important;
        }
        
    }
    .n-img{
        top: 15px;
    }
    .n-text{
        top: 120px;
    }
    .btn-footer{
        bottom: -8px;
        right: 0;
        padding: 5px 25px;
    }
}
.travel-story{
    h2{
        font-size: 1.6rem;
    }
    margin-top: 0px;
    .prev{
        height: 30px;
        width: 30px;
        top: 50%;
        left: 25px;
        margin-right: 10px;
        cursor: pointer;
        padding: 0px 9px !important;
        z-index: 10;
    }
    .next{
        height: 30px;
        width: 30px;
        top: 50%;
        right: 25px;
        margin-right: 10px;
        cursor: pointer;
        padding: 0px 6px !important;
        z-index: 10;
    }
    .disabled{
        background-color: #000000f2 !important;
    }
    .w-h{
        width: 235px !important;
        height: 310px !important;
    }
    .mr{
        margin-right: 60px;
    }
    .btn-out-let{
        top: 20px;
        left: 10px;
        color: white;
        border: 1px solid white;
        border-radius: 20px;
        padding: 2px 15px;
    }
    .txt-footer{
        bottom: 35px;
        left: 0px;
        color: white;
        padding: 2px 20px;
        font-size: 17px;
    }
    .read-more-footer{
        bottom: 10px;
        right: 20px;
        color: white;
        font-size: 12px;
    }
    .ts-img{
        width: 235px !important;
        height: 310px !important;
        opacity: 0.8 !important;
    }
}
.testimonials{
    h2{
        font-size: 1.6rem;
    }
    .h2{
        b{
            color: #0F3B50 !important;
        }
    }
    margin-top: 0px;
    .prev{
        height: 40px;
        width: 40px;
        top: 50%;
        left: 50px;
        margin-right: 10px;
        cursor: pointer;
        padding: 0px 13px !important;
        z-index: 10;
        svg{
            color:#000000f2;
        }
    }
    .next{
        height: 40px;
        width: 40px;
        top: 50%;
        right: 50px;
        margin-right: 10px;
        cursor: pointer;
        padding: 0px 6px !important;
        z-index: 10;
        svg{
            color:#000000f2;
        }
    }
    .c{
        margin-left: 70px;
        margin-right: 70px;
        width: 1000px;
    }
    .disabled{
        background-color: #000000f2 !important;
    }
    .w-h{
        width: 235px !important;
        height: 235px !important;
    }
    .mr{
        margin-right: 60px;
    }
    .pl{
        border-radius: 50px;
        width: 50px;
        height: 50px;
        max-width: 50px;
    }
    .s-p{
        font-size: 12px;
    }
    .sb-p{
        font-size: 10px;
    }
    .sm-p{
        font-size: 7px;
    }
    .sx-p{
        font-size: 6px;
    }
    .footer-block{
        position: absolute;
        bottom: 0;
        left: 0;
        height: 10px;
        width: 100%;
        background: linear-gradient( 0deg, #1b1c1d 20%, #1b1c1d 90%) !important;
    }
    .mx-5x{
        margin-left: 3.5rem;
        margin-right: 3.5rem;
    }
    .description{
        .d-tab{
            cursor: pointer;
            span{
                font-size: 16.5px;
                text-transform: uppercase;
                font-weight: bold;
                border-bottom: 2px solid white;
            }
        }
        .active{
            span{
                border-bottom: 2px solid #1b1c1d;
                color: #1b1c1d;
            }
        }
    }
    .footer-palne{
        width: 600px;
        position: unset;
        bottom: 680px;
        right: -145px;
    }
    
}
.h2 {
    color: #4C4C4C !important;
}
.w-390-px{
    width: 350px;
    max-width: 350px;
    min-width: 350px;
}

.stay-up-to-date{
    .girl-plane{
        width: 90%;
    }
    .form-btn{
        text-transform: uppercase;
        font-size: 17px;
        padding: 7px 30px;
        margin-top: 2px;
    }
    .play-store{
        width: 145px;
    }
    .apple-store{
        width: 145px;
    }
}
.footer{
    background-color: black;
    .list{
        list-style: none;
        display: flex;
        margin: auto;
        li{
            margin-left: 10px;
            margin-right: 10px;
            padding-left: 10px;
            padding-right: 10px;
            text-transform: uppercase;
            font-weight: bolder;
        }
    }
    .of-img{
        width: 160px;
        height: 60px;
    }
    .f-img{
        width: 75%;
    }
    .footer-img{
        width: 100%;
        margin-top: 55px;
        border-bottom: 1px solid #dee2e6;
    }
}

