.bg-img{
    background: url('../../assets/Icons/bg.png');
    background-position: bottom;
}
.bg-img2{
    background: url('../../assets/Icons/bg-img.png');
}
.nav-bar > li:hover{
    border-bottom: 2px solid rgb(0, 0, 0) !important;
}
.f-city > li:hover{
    border-bottom: 2px solid #5356CC !important;
    color: #5356CC;
}
.sf-list > li:hover{
    /* border-bottom: 2px solid #5356CC; */
    color: #5356CC;
    /* font-weight: bolder !important; */
}
.tab:hover > hr{
    color: #8D85F5 !important;
}
.d-tab:hover > span{
    border-bottom: 2px solid #434883 !important;
    color: #434883 !important;
}
.route-card:before {
    content:'';
    position:absolute;
    width:100%;
    height:30px;
    background:linear-gradient(to left, #c4268c, #9a0b72);
    top:-30px;
    left:0;
}