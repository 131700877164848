.app{
  height: 100vh;
}

body{
  padding: 0;
  margin: 0;
}
.ui.segment {
  position: relative;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
  margin: 0rem 0;
  padding: 0em 0em;
  border-radius: 0;
  border: 0px solid rgba(34,36,38,.15);
}


@media (max-width: 575.98px) {
  .app{
    height: var(--app-height) !important;
  }

  .pushable .pusher {
    height: 100% !important;
  }

  .Home{
    height: 100% !important;
  }

  .SearchResult{
    height: 100% !important;
  }

  .ReviewFlight{
    height: 100% !important;
  }

  .search-filter-for-mobile{
    height: calc(100vh - 140px) !important;
  }

  .xs-main-container .header{
    height: var(--app-header-height);
  }
  .xs-main-container .body-container{
    height: calc(var(--app-height) - var(--app-header-height));
    overflow-y: auto;
    overflow-x: hidden;
  }

  .xs-round-trip-section{
    height: calc(var(--app-height) - var(--app-header-height) - 8px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .clear-cancel{
    top: -5px !important;
  }
}



