iframe{
    display: none !important;
}

.h-100vh{
    height:100vh;
}

.h-95vh{
    height:95vh;
}
.h-90vh{
    height:90vh;
}


.bg-gradient{
    background: linear-gradient(-10deg, #f8f9fa -15%, #000000f2 80%) !important;
}
.bg-gradient-2 {
    background: linear-gradient(316deg , #f8f9fa -15%, #000000f2 75%) !important;
}
.color-w{
    color: white !important;
}
.my-big-input{
    font-size: 20px;
    padding-left: 70px;
    padding-top: 15px;
    border-radius: 12px;
    font-weight: 500;
    padding-bottom: 15px;
}
.my-icon{
    left: 30px !important;
    top: 18px !important;
    font-size: 28px !important;
    color: #8B8B8B !important;
}
.my-default-btn{
    background: white;
    padding: 15px !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    color: #8B8B8B;
    border-radius: 50px !important;
}
.my-default-btn:active{
    background: #e0e0e2 !important;
}

.w-100-per{
    width: 100% !important;
}
.w-100-vw{
    width: 100vw !important;
}
.h-100-vw{
    height: 100vh !important;
}
.text-right{
    text-align: right !important;
}
.border-primary{
    border: 1px solid #000000f2 !important;
}
.shadow{
    box-shadow: '0 2px 5px 1px #d4d4d5';
}
.border-0{
    border: 0 !important;
}
.active-card-border{
    border: 2px solid #000000f2 !important;
}
.my-success-btn{
    background: white;
    padding: 15px !important;
    font-size: 18px;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    color: #8B8B8B;
    border-radius: 50px !important;
}
.w-color{
    color: white !important;
}
.h2{
    line-height: 42px;
    font-size: 30px;
}
.r-color{
    color: #C3351A;
}
.l-s{
    font-weight: 600;
    font-size: 13px;
}
.l-b{
    font-weight: bolder;
}
.h{
    color: #4C4C4C;
}
.h2 {
    color: #4C4C4C !important;
}
.border-left{
    border-left: 1px solid #dee2e6!important;
}
.bold{
    font-weight: bold;
}
.sm-p{
    font-size: 10px;
}
.mb-2rem{
    margin-bottom: 2rem!important;
}
.color-primary{
    color: #000000f2 !important;
}
.pd-10{
    padding: 10px !important;
}
.h-120vh{
    height: 570px !important;
}
.bg-cream{
    background-color: #F2F2F2 !important;
}
.border-radius{
    border-radius: 10px !important;
}
.size-200-200{
    width: 100px;
    height: 100px;
}
.p{
    color: #9F9F9F !important;
}
.box-shadow{
    box-shadow: 0px 1px 6px 1px #dee2e6 !important;
}
.font-15{
    font-size: 15px !important;
}
.mr-45-px{
    margin-right: 60px !important;
}
.pl-3{
    padding-left: 0.75rem !important;
}
.float-left
{
    float: left !important;
}
.float-right
{
    float: right !important;
}
.m-auto{
    margin: auto !important;
}
.text-left{
    text-align: left !important;
}
.h-c{
    color: #3a3a3a !important
}
.font-bolder{
    font-weight: bolder !important;
}
.t-color{
    color: #272727 !important;
}
.t-g-color{
    color: #757575 !important;
}
.input-text-center{
    input{
        text-align: center !important;
    }
}
.gradient-color{
    color: #000000f2;
}
.gray-color{
    color:#888888 !important;
}
.f-w-600{
    font-weight: 600 !important;
}
.gradient2-color{
    color:#000000f2 !important;
}
.bg-light-gray{
    background-color: #F7F7F7 !important;
}
.svg-icon{
    path{
        fill: #000000f2 !important;
    }
}
.svg-white{
    path{
        fill: white !important;
    }
}
.bg-white{
    background-color: white !important;
}
.a{
    cursor: pointer;
    color: #000000f2 !important;
}
.my-model{
    margin: auto !important;
    height: auto !important;
    border-radius: 15px !important;
    left: 30% !important;
    top: 20% !important;
}

.passengerList{
    img{
        width: 25px !important;
    }
}
.title-weeks{
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: row-reverse;
    border-bottom: 1px solid #e2e2e2;
    margin: 5px 0 10px 0;
    font-size: 15px;
    p {
        width: 50px;
        text-align: center;
        margin-bottom: 0px;
        padding: 10px;
    }
}
.week{
    .select-mode{
        width: 45px !important;
        height: 45px !important;
        text-align: center !important;
    }
}
.sc-gsTCUz {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 55px;
    background-color: #000000f2 !important;
    background: linear-gradient(-10deg, #f8f9fa -15%, #000000f2 80%) !important;
    position: relative;
}
// .sc-gsTCUz{

// }
.sc-hKgILt{
    .month{
        .week{
            .select-mode:hover{
                background-color: #000000 !important;
            }
            .selected {
                color: #fff;
                background-color: #000000f2 !important;
                box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%) !important;
                background: linear-gradient(-10deg, #f8f9fa -15%, #000000f2 80%) !important;
            }
            .selected:hover {
                background-color: #000000f2 !important;
                background: linear-gradient(-10deg, #f8f9fa -15%, #000000f2 80%) !important;
            }
            
        }
    }
}

.sc-gsTCUz{
    background-color: #000000f2 !important;
    background: linear-gradient(-10deg, #f8f9fa -15%, #000000f2 80%) !important;
}

.tp-calendar{
    div{
        .date-picker{
            div{
                .week{
                    // .selected {
                    //     color: #fff;
                    //     background-color: #000000f2 !important;
                    //     box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%) !important;
                    //     background: linear-gradient(-10deg, #f8f9fa -15%, #000000f2 80%) !important;
                    // }
                    // .selected:hover {
                    //     background-color: #000000f2 !important;
                    //     background: linear-gradient(-10deg, #f8f9fa -15%, #000000f2 80%) !important;
                    // }
                    .select-mode{
                        color: black !important;
                    }
                    .disabled::after {
                        position: absolute;
                        content: '';
                        width: 15px;
                        height: 2px;
                        background-color: #e91e63;
                        -webkit-transform: rotate(
                    -20deg
                    );
                        -ms-transform: rotate(-20deg);
                        transform: rotate(
                    -20deg
                    );
                    }
                }
            }
        }
    }
}

.c-dropdown{
    border: 1px solid #DEE2E6;
    .c-dropdown-list{
        .selected{
            background: linear-gradient(
            0deg
            , #f8f9fa -15%, #000000f2 80%) !important;
            color: white !important;
        }
        .c-dropdown-list-item:hover{
            cursor: pointer;
            background-color: #DEE2E6;
        }
        .c-dropdown-list-item:active{
            background: linear-gradient(
            0deg
            , #f8f9fa -15%, #000000f2 80%) !important;
            color: white !important;
        }
    }
}

.my-sUi-input-border-0{
    input{
        border-radius: 0px !important;
    }
}

.my-btn-a{
    width: 100% !important;
    font-size: unset !important;
    cursor: unset !important;
    display: unset !important;
    min-height: unset !important;
    outline: unset !important;
    border: unset !important;
    vertical-align: unset !important;
    background: unset !important;
    color: unset !important;
    font-family: unset !important;
    margin: unset !important;
    padding: unset !important;
    text-transform: unset !important;
    text-shadow: unset !important;
    font-weight: unset !important;
    line-height: unset !important;
    font-style: unset !important;
    text-align: unset !important;
    text-decoration: unset !important;
    border-radius: unset !important;
    box-shadow: unset !important;
    -webkit-user-select: unset !important;
    user-select: unset !important;
    transition: unset !important;
    will-change: unset !important;
    -webkit-tap-highlight-color: unset !important;
}


.active-text{
    color: #000000f2 !important;
    font-weight: bolder !important;
}

.my-progress-primary{
    max-width: 88% !important;
    height: 5px !important;
    .bar {
        height: 5px !important;
    }
}

.btn-spinner{
    width: 25px !important;
    height: 25px !important;
    position: absolute;
}


.h6{
    font-size: 11px !important;
}

.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
    background-color: unset !important;
}

.modal-95w{
    min-width: 95vw !important;
}

.modal-100w{
    min-width: 100vw !important;
}

.modal-500-px{
    min-width: 500px !important;
}

.seat-amount{
    position: absolute;
    -ms-transform: rotate(45deg);
    -ms-transform-origin: 20% 40%;
    transform: rotate(-90deg);
    transform-origin: 0% 23%;
    font-size: 10px;
    top: 30px;
    left: 14px;
}

.home-login-btn{
    position: absolute;
    z-index: 10;
    left: -100px;
    top: 20px;
    background-color: white !important;
    &:active{
        background-color:#f5f3f3 !important;
    }
}

.profile-logo-dropdown{
    position: absolute;
    z-index: 10;
    left: -100px;
    top: 15px;
}

.tab-menu-m-0{
    .menu{
        margin: 0 !important;
    }
}

.my-calender{
    .aa{
        cursor: pointer !important;
        color: #000000f2;
    }
    .td-h{
        height: 55px;
        width: 55px;
    }
    .calender-td{
        height: 55px;
        &:hover{
            background-color: #000000f2;
            color: white !important;
        }
    }
    .aa{
        span{
            font-size: 10px;
        }
    }
    .selected{
        background-color: #000000f2 !important;
        color: white !important;
    }
    .between-selected{
        background: linear-gradient(-10deg, #f8f9fa -15%, #000000f2 80%) !important;
        // background-color: #000000;
        color: white;
    }

    .disabled{
        background-color: rgb(158 158 158 / 16%);
        color: rgb(158 158 158 / 66%);
    }
}

.mobile-nav{
    display: none;
    .bg-none{
        background-color: unset;
        background: unset;
    }
    .login-btn {
        float: right;
        z-index: 0;
        background-color: white !important;
    }
}

.desktop-nav{
    display: unset !important;
}

.mobile{
    display: none !important;
}

.desktop{
    display: unset !important;
}

.xs-device{
    display: none !important;
}

.sm-device{
    display: none !important;
}

.md-device{
    display: none !important;
}

.lg-device{
    display: none !important;
}


.xl-device{
    display: none !important;
}

.xs-device-block{
    display: none !important;
}

.sm-device-block{
    display: none !important;
}

.md-device-block{
    display: none !important;
}

.lg-device-block{
    display: none !important;
}

.xl-device-block{
    display: none !important;
}

.login-signup-tab{
    .menu{
        margin-bottom: 0 !important;
        z-index: 10 !important;
        .active{
            background: linear-gradient( -10deg, #f8f9fa -15%, #000000f2 80%) !important;
            color: white !important;
        }
    }
    
}

.overflow-auto{
    overflow: auto;
}

.Login-mobile{
    .logo {
        width: 200px;
    }

    .my-big-input {
        font-size: 15px;
        padding-left: 45px;
        padding-top: 10px;
        border-radius: 8px;
        font-weight: 500;
        padding-bottom: 10px;
    }
    
    .pl-35 {
        padding-left: 18px !important;
    }

    .h2{
        margin: 0 !important;
    }
    .p{
        margin: 0 !important;
    }

    .form-div{
        padding-top: 10px !important;
    }

    .my-icon {
        left: 10px !important;
        top: 7px !important;
        font-size: 28px !important;
        color: #8B8B8B !important;
    }
    
    
}

.rotation-90{
    transform: rotate(-90deg) !important;
}

.w-98{
    width: 98% !important;
}

.fromDropdownMobile{
    width: 100vw !important;
    height: 100vh !important;
    .c-dropdown-list{
        max-height: unset !important;
    }
}

.travellersMobile{
    width: 100vw !important;
    height: 100vh !important; 
}

.d-unset{
    display: unset;
}

.vertical-calendar{
    .vertical-calendar-card{
        .active-date{
            h3{
                color: black !important;
                font-weight: bolder !important;
            }
        }
    }
}


// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    body ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0px !important;
        height: 0px !important;
    }
    .travellers-details-heading{
        font-size: 15px !important;
    }

    .xs-modal-100w{
        min-width: 100vw !important;
    }

    .xs-w-100{
        width: 100% !important;
    }

    .xs-w-90{
        width: 90% !important;
    }

    .not-found{
        h1{
            font-size: 15px !important;
        }

        button{
           width: 100% !important; 
           font-size: 13px !important;
        }
    }

    .something-went-wrong{
        h1{
            font-size: 15px !important;
        }

        button{
           width: 100% !important; 
           font-size: 13px !important;
        }
    }

    .baggage{
        img{
            width: 20px !important;
        }
        .price{
            font-size: 10px !important;
        }
        .text{
            font-size: 11px !important;
        }
        .button{
            padding: 0px !important;
        }

    }
    .meals{
        img{
            width: 20px !important;
        }
        .price{
            font-size: 10px !important;
        }
        .text{
            font-size: 11px !important;
        }
        .button{
            padding: 0px !important;
        }

    }

    .contact-information{
        img{
            width: 40px !important;
        }
        h4{
            font-size: 11px !important;
        }
    }
    .gst-block{
        img{
            width: 40px !important;
        }
        h4{
            font-size: 11px !important;
        }
        p{
            font-size: 10px !important;
        }
        .button{
            padding: 0px 5px !important;
            font-size: 10px !important;
        }
    }

    .seats-detail{
        img{
            width: 40px !important;
        }
        h4{
            font-size: 11px !important;
        }
        p{
            font-size: 10px !important;
        }
        .button{
            padding: 0px 5px !important;
            font-size: 10px !important;
            svg{
                width: 10px !important;
            }
        }
    }

    .promo-code-details{
        .apply-coupan{
            font-size: 12px !important;
        }
        .or{
            padding: 3px !important;
            font-size: 10px !important;
            border: 1px solid black !important;
            border-radius: 50px !important;
        }
        .PromoCode{
            h4{
                font-size: 13px !important;
            }
        }
    }
    

    .TravellerDetails-TreeViews{
        li{
            .MuiTreeItem-content{
                .MuiTreeItem-label{
                    b{
                        font-size: 10px !important;
                    }
                }
            }
            .MuiCollapse-container{
                margin-left: 5px !important;
                .MuiCollapse-wrapper{
                    .MuiCollapse-wrapperInner{
                        .MuiGrid-container{

                        }
                    }
                }
            }
        }
    }
    .ReviewFlight{
        .login-details{
            .logged-in-as{
                font-size: 15px !important;
            }
            .p-heading{
                font-size: 13px !important;
            }
            .p-sub-heading{
                font-size: 10px !important;
            }
         }
        .flightDetails{
            .heading{
                font-size: 14px !important;
            }
            .sub-heading{
                font-size: 12px !important;
                margin-top: 5px !important;
            }
            .partially-refundable{
                font-size: 10px !important;
            }
            .arrowForword{
                width: 20px !important;
                height: 18px !important;
            }
            .date-stop-time{
                font-size: 10px !important;
                float: left !important  ;
            }

            .flight-meta-data{
                .airlines-logo{
                    width: 30px !important;
                    height: 27px !important;
                }
                p{
                    font-size: 10px !important;
                }
                .flight-card{
                    h2{
                        font-size: 14px !important;
                    }
                    h4{
                        font-size: 11px !important;
                    }
                }
            }
        }
    }
    .VarticalSearchCard{
        .fromDropdown {
            top: 120px !important;
            left: 25px !important;
        }
        .toDropdown {
            top: 120px !important;
            left: unset !important;
            right: 25px !important;
        }
        .travellers {
            top: 290px !important;
            left: 25px !important;
            z-index: 10 !important;
        }
    }
    .vertical-calendar{
        .vertical-calendar-card{
            .calendar-element{
                width: 65px !important;
            }
            .p{
                font-size: 11px;
            }
            h4{
                font-size: 10px;
            }

            .active-date{
                h3{
                    color: black !important;
                    font-weight: bolder !important;
                }
            }
        }
    }

    .vertical-calendar::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0px !important;
        height: 0px !important;
    }

    .search-card{
        .fromDropdown {
            top: 380px !important;
            left: 42px !important;
        }

        .toDropdown {
            top: 380px !important;
            left: unset !important;
            right: 35px !important;
        }

        .travellers {
            top: 540px !important;
            left: 42px !important;
            z-index: 10 !important;
        }
    }

    .search-least{
        .search-card{
            .h3{
                font-size: 10px !important;
            }
            p{
                font-size: 7px !important;
            }
        }
    }
    
    @for $i from 0 through 20 {
        .xs-pe-#{$i} {
            padding-right: #{$i * 0.5}rem !important;
        }
        .xs-ps-#{$i} {
            padding-left: #{$i * 0.5}rem !important;
        }
        .xs-pt-#{$i} {
            padding-top: #{$i * 0.5}rem !important;
        }
        .xs-pb-#{$i} {
            padding-bottom: #{$i * 0.5}rem !important;
        }


        .xs-me-#{$i} {
            margin-right: #{$i * 0.5}rem !important;
        }
        .xs-ms-#{$i} {
            margin-left: #{$i * 0.5}rem !important;
        }
        .xs-mt-#{$i} {
            margin-top: #{$i * 0.5}rem !important;
        }
        .xs-mb-#{$i} {
            margin-bottom: #{$i * 0.5}rem !important;
        }


        .xs-me-n#{$i} {
            margin-right: -#{$i * 0.5}rem !important;
        }
        .xs-ms-n#{$i} {
            margin-left: -#{$i * 0.5}rem !important;
        }
        .xs-mt-n#{$i} {
            margin-top: -#{$i * 0.5}rem !important;
        }
        .xs-mb-n#{$i} {
            margin-bottom: -#{$i * 0.5}rem !important;
        }

        .xs-px-#{$i} {
            padding-right: #{$i * 0.5}rem !important;
            padding-left: #{$i * 0.5}rem !important;
        }
        .xs-py-#{$i} {
            padding-top: #{$i * 0.5}rem !important;
            padding-bottom: #{$i * 0.5}rem !important;
        }

        .xs-p-#{$i} {
            padding: #{$i * 0.5}rem !important;
        }

        .xs-m-#{$i} {
            margin: #{$i * 0.5}rem !important;
        }

        .xs-m-n#{$i} {
            margin: #{$i * 0.5}rem !important;
        }

        .xs-mx-#{$i} {
            margin-right: #{$i * 0.5}rem !important;
            margin-left: #{$i * 0.5}rem !important;
        }
        .xs-my-#{$i} {
            margin-top: #{$i * 0.5}rem !important;
            margin-bottom: #{$i * 0.5}rem !important;
        }

        .xs-mx-n#{$i} {
            margin-right: -#{$i * 0.5}rem !important;
            margin-left: -#{$i * 0.5}rem !important;
        }
        .xs-my-n#{$i} {
            margin-top: -#{$i * 0.5}rem !important;
            margin-bottom: -#{$i * 0.5}rem !important;
        }
    }

    .xs-h-100-vh{
        height: 100vh !important;
    }

    .xs-h-99-5-vh{
        height: 99.5vh !important;
    }

    .pushable{
        .pusher{
            height: 100vh;
            overflow: auto;
        }
        
        .dimmed{
            overflow: hidden !important;
        }
    }

    .xs-pe-0{
        padding-right: 0px !important;
    }

    .xs-pe-2{
        padding-right: .5rem !important;
    }

    .xs-ps-3{
        padding-right: 1rem !important;
    }

    
    .xs-p-0{
        padding: 0px !important;
    }

    .xs-pb-5{
        padding-bottom: 3rem !important;
    }

    .xs-pt-2{
        padding-bottom: .5rem !important;
    }

    .xs-px-0{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .xs-px-1{
        padding-left: .25rem !important;
        padding-right: .25rem !important;
    }

    .xs-px-2{
        padding-left: .5rem !important;
        padding-right: .5rem !important;
    }

    .xs-px-3{
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .xs-pe-20-px{
        padding-right: 20px !important;
    }

    .xs-text-center{
        text-align: center !important;
    }

    .xs-border-end{
        border-right: 1px solid #dee2e6!important;
    }
    

    .mt-xs-4{
        margin-top: 1.5rem !important;
    }

    .pt-xs-3{
        padding-top: 1rem !important;
    }

    .border-xs-top{
        border-top: 1px solid #dee2e6 !important;
    }

    .border-xs-bottom{
        border-bottom: 1px solid #dee2e6 !important;
    }

    .border-xs-left{
        border-left: 1px solid #dee2e6 !important;
    }

    .border-xs-left-0{
        border-left: 0px solid #dee2e6 !important;
    }
    

    .border-xs-right{
        border-right: 1px solid #dee2e6 !important;
    }

    .search-card{
        margin-top: 0px !important;
    }

    .float-xs-start{
        float: left !important;
    }

    .h-120vh {
        height: 700px !important;
    }

    .travellers {
        top: 665px !important;
        left: 42px !important;
        z-index: 10 !important;
    }

    .mobile-nav{
        display: unset !important;
        .login-btn {
            float: right;
            z-index: 0;
            background-color: white !important;
        }
        .profile-logo-dropdown-mobile {
            float: right;
            z-index: 0;
        }
    }

    .xs-device{
        display: unset !important;
    }

    .xs-device-block{
        display: block !important;
    }

    .desktop-nav{
        display: none !important;
    }

    .mobile{
        display: unset !important;
    }

    .desktop{
        display: none !important;
    }

    .modal-95w {
        min-width: 99vw !important;
        width: 99vw !important;
    }

    .search-card-heading{
        margin-top: 10px !important;
        h2{
            margin: 0 !important;
            font-size: 18px !important;
        }
    }

    .h-120vh{
        padding: 0px 1rem !important;
    }
    .header{
        .left-logo-section {
            top: -100px;
            left: unset !important;
            right: -129px !important;
            width: 400px;
            height: 200px;
            border-radius: 0px 0px 200px 200px;
        }
    }

    .fromDropdown {
        top: 500px !important;
        left: 42px !important;
    }

    .toDropdown {
        top: 500px !important;
        left: unset !important;
        right: 35px !important;
    }
    .modal-dialog-sm {
        max-width: 97vw !important;
        margin: 1.75rem auto !important;
    }

    .pdfr {
        width: 100% !important;
       .next {
            float: right;
            height: 30px !important;
            width: 30px !important;
            padding: 0px 5px !important;
            cursor: pointer;
            svg{
                width: 15px !important;
            }
        }

        .prev {
            float: left;
            height: 30px !important;
            width: 30px !important;
            padding: 0px 9px !important;
            cursor: pointer;
            svg{
                width: 15px !important;
            }
        }

        h2{
            font-size: 18px !important;
        }

        .pdfr-slider {
            width: 100% !important;
            overflow: hidden;
            .slick-list{
                width: 120% !important;
                .slick-track {
                    padding-left: 48px !important;
                }
            }
        }
    }

    .footer {
        .f-img {
            width: 145px !important;
        }
    }

 }

 .shimmer-container{
    .title {
        width: 15%;
      }
      .link {
        width: 90%;
      }
      .description {
        width: 70%;
      }
      .shimmer {
        padding: 5px;
        width: 95%;
        height: 120px;
        margin: 5px auto;
        background: #ffffff;
      }
      .shimmer .image-card {
        height: 90px;
        width: 90px;
        float: right;
        border-radius: 8px;
      }
      .stroke {
        height: 15px;
        background: #777;
        margin-top: 20px;
        
      }
      .stroke2 {
        height: 50px;
        background: #777;
        margin-top: 20px;
      }
      
      .wrapper {
        width: 0px;
        animation: fullView 0.5s forwards linear;
      }
      
      @keyframes fullView {
        100% {
          width: 100%;
        }
      }
      .animate {
        animation: shimmer 3s;
        animation-iteration-count: infinite;
        background: linear-gradient(to right, #e6e6e6 5%, #cccccc 25%, #e6e6e6 35%);
        background-size: 1000px 100%;
      }
      @keyframes shimmer {
        from {
          background-position: -1000px 0;
        }
        to {
          background-position: 1000px 0;
        }
      }
 }

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) { 

    .border-sm-top{
        border-top: 1px solid #dee2e6 !important;
    }

    .sm-text-center{
        text-align: center !important;
    }

    .pdfr-slider {
        width: 100% !important;
        overflow: hidden;
        .slick-list{
            width: 120% !important;
            .slick-track {
                padding-left: 48px !important;
            }
        }
    }

    .pushable{
        .pusher{
            height: 100vh;
            overflow: auto;
        }
        
        .dimmed{
            overflow: hidden !important;
        }
    }
    .mobile-nav{
        display: unset !important;
    }

    .sm-device{
        display: unset !important;
    }

    .sm-device-block{
        display: block !important;
    }

    .desktop-nav{
        display: none !important;
    }

    .mobile{
        display: unset !important;
    }

    .desktop{
        display: none !important;
    }

    .search-card-heading{
        margin-top: 80px !important;
        h2{
            margin: 0 !important;
        }
    }

    .modal-dialog-sm {
        max-width: 97vw !important;
        margin: 1.75rem auto !important;
    }
    .footer {
        .f-img {
            width: 145px !important;
        }
    }

    @for $i from 0 through 20 {
        .sm-pe-#{$i} {
            padding-right: #{$i * 0.5}rem !important;
        }
        .sm-ps-#{$i} {
            padding-left: #{$i * 0.5}rem !important;
        }
        .sm-pt-#{$i} {
            padding-top: #{$i * 0.5}rem !important;
        }
        .sm-pb-#{$i} {
            padding-bottom: #{$i * 0.5}rem !important;
        }


        .sm-me-#{$i} {
            margin-right: #{$i * 0.5}rem !important;
        }
        .sm-ms-#{$i} {
            margin-left: #{$i * 0.5}rem !important;
        }
        .sm-mt-#{$i} {
            margin-top: #{$i * 0.5}rem !important;
        }
        .sm-mb-#{$i} {
            margin-bottom: #{$i * 0.5}rem !important;
        }


        .sm-me-n#{$i} {
            margin-right: -#{$i * 0.5}rem !important;
        }
        .sm-ms-n#{$i} {
            margin-left: -#{$i * 0.5}rem !important;
        }
        .sm-mt-n#{$i} {
            margin-top: -#{$i * 0.5}rem !important;
        }
        .sm-mb-n#{$i} {
            margin-bottom: -#{$i * 0.5}rem !important;
        }

        .sm-px-#{$i} {
            padding-right: #{$i * 0.5}rem !important;
            padding-left: #{$i * 0.5}rem !important;
        }
        .sm-py-#{$i} {
            padding-top: #{$i * 0.5}rem !important;
            padding-bottom: #{$i * 0.5}rem !important;
        }

        .sm-p-#{$i} {
            padding: #{$i * 0.5}rem !important;
        }

        .sm-m-#{$i} {
            margin: #{$i * 0.5}rem !important;
        }

        .sm-m-n#{$i} {
            margin: #{$i * 0.5}rem !important;
        }

        .sm-mx-#{$i} {
            margin-right: #{$i * 0.5}rem !important;
            margin-left: #{$i * 0.5}rem !important;
        }
        .sm-my-#{$i} {
            margin-top: #{$i * 0.5}rem !important;
            margin-bottom: #{$i * 0.5}rem !important;
        }

        .sm-mx-n#{$i} {
            margin-right: -#{$i * 0.5}rem !important;
            margin-left: -#{$i * 0.5}rem !important;
        }
        .sm-my-n#{$i} {
            margin-top: -#{$i * 0.5}rem !important;
            margin-bottom: -#{$i * 0.5}rem !important;
        }
    }
 }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {


    .md-text-center{
        text-align: center !important;
    }

    .way-details{
        width: 75px !important;
    }

    .border-md-top{
        border-top: 1px solid #dee2e6 !important;
    }

    .air-plane-logo{
        width: 30px !important;
    }

    .footer-book-now-btn{
        width: 100px !important;
    }

    .footer-price-some{
        font-size: 20px !important;
    }

    .footer-flight-details{
        font-size: 11px !important;
    }

    .pdfr-slider {
        .slick-list{
            .slick-track {
                padding-left: 35px !important;
            }
        }
    }

    .pushable{
        .pusher{
            height: 100vh;
            overflow: auto;
        }
        
        .dimmed{
            overflow: hidden !important;
        }
    }
    .f-city{
        li{
            font-size: 12px !important;
        }
    }
    .modal-dialog-sm {
        max-width: 97vw !important;
        margin: 1.75rem auto !important;
    }

    .VarticalSearchCard{
        .h3{
            font-size: 20px !important;
        }
        .s-label{
            font-size: 12px !important;
        }
        .departure{
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        .to{
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        .from{
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        .fromDropdown {
            top: 254px !important;
            left: 35px !important;
        }

        .toDropdown {
            top: 254px !important;
            left: 170px !important;
        }

        .travellers {
            top: 254px !important;
            left: 450px !important;
            z-index: 10;
        }  
    }
    .search-card{
        .s-label{
            font-size: 12px !important;
        }
        .departure{
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        .to{
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        .from{
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        .fromDropdown {
            top: 365px !important;
            left: 58px !important;
        }

        .toDropdown {
            top: 365px !important;
            left: 173px !important;
        }

        .travellers {
            top: 365px !important;
            left: 450px !important;
            z-index: 10;
        }
    }
    
    .mobile-nav{
        display: unset !important;
        .login-btn {
            float: right;
            z-index: 0;
            margin-right: 45px;
            background-color: white !important;
        }
        .profile-logo-dropdown-mobile{
            float: right;
            z-index: 0;
            margin-right: 0px;
        }
    }

    .md-device{
        display: unset !important;
    }

    .md-device-block{
        display: block !important;
    }

    .desktop-nav{
        display: none !important;
    }

    .mobile{
        display: unset !important;
    }

    .desktop{
        display: none !important;
    }
    .bg-png-fixed {
        position: fixed;
        right: 0;
        height: 724px;
        width: 40% !important;
    }

    .profile-logo-dropdown {
        left: -150px !important;
        top: 24px !important;
        width: 100px !important;
    }

    .logo-fixed {
        position: fixed;
        z-index: 0 !important;
        top: 10px !important;
        width: 120px !important;
        right: 0;
    }

    .plane-img-fixed {
        position: fixed;
        z-index: 0 !important;
        top: 100px !important;
        right: -89px;
        width: 45%;
    }
    .search-card-heading{
        margin-top: 80px !important;
        h2{
            margin: 0 !important;
        }
    }

    .home-login-btn {
        position: absolute;
        z-index: 10;
        left: -240px !important;
        top: 30px;
        background-color: white !important;
    }
    
    .Login{
        .plane-img {
            width: 500px !important;
            margin-top: -30px;
            margin-left: -120px;
        }
    }
    .header{
        .left-logo-section {
            top: -100px;
            left: unset !important;
            right: -129px !important;
            width: 400px;
            height: 200px;
            border-radius: 0px 0px 200px 200px;
            .f-img {
                bottom: 6px !important;
                right: 137px !important;
            }
        }
    }
    .footer {
        .f-img {
            width: 145px !important;
        }
    }

    @for $i from 0 through 20 {
        .md-pe-#{$i} {
            padding-right: #{$i * 0.5}rem !important;
        }
        .md-ps-#{$i} {
            padding-left: #{$i * 0.5}rem !important;
        }
        .md-pt-#{$i} {
            padding-top: #{$i * 0.5}rem !important;
        }
        .md-pb-#{$i} {
            padding-bottom: #{$i * 0.5}rem !important;
        }


        .md-me-#{$i} {
            margin-right: #{$i * 0.5}rem !important;
        }
        .md-ms-#{$i} {
            margin-left: #{$i * 0.5}rem !important;
        }
        .md-mt-#{$i} {
            margin-top: #{$i * 0.5}rem !important;
        }
        .md-mb-#{$i} {
            margin-bottom: #{$i * 0.5}rem !important;
        }


        .md-me-n#{$i} {
            margin-right: -#{$i * 0.5}rem !important;
        }
        .md-ms-n#{$i} {
            margin-left: -#{$i * 0.5}rem !important;
        }
        .md-mt-n#{$i} {
            margin-top: -#{$i * 0.5}rem !important;
        }
        .md-mb-n#{$i} {
            margin-bottom: -#{$i * 0.5}rem !important;
        }

        .md-px-#{$i} {
            padding-right: #{$i * 0.5}rem !important;
            padding-left: #{$i * 0.5}rem !important;
        }
        .md-py-#{$i} {
            padding-top: #{$i * 0.5}rem !important;
            padding-bottom: #{$i * 0.5}rem !important;
        }

        .md-p-#{$i} {
            padding: #{$i * 0.5}rem !important;
        }

        .md-m-#{$i} {
            margin: #{$i * 0.5}rem !important;
        }

        .md-m-n#{$i} {
            margin: #{$i * 0.5}rem !important;
        }

        .md-mx-#{$i} {
            margin-right: #{$i * 0.5}rem !important;
            margin-left: #{$i * 0.5}rem !important;
        }
        .md-my-#{$i} {
            margin-top: #{$i * 0.5}rem !important;
            margin-bottom: #{$i * 0.5}rem !important;
        }

        .md-mx-n#{$i} {
            margin-right: -#{$i * 0.5}rem !important;
            margin-left: -#{$i * 0.5}rem !important;
        }
        .md-my-n#{$i} {
            margin-top: -#{$i * 0.5}rem !important;
            margin-bottom: -#{$i * 0.5}rem !important;
        }
    }
 }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) { 
    .VarticalSearchCard{
        .s-label{
            font-size: 12px !important;
        }
        .departure{
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        .to{
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        .from{
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        .fromDropdown {
            top: 254px !important;
            left: 35px !important;
        }

        .toDropdown {
            top: 254px !important;
            left: 170px !important;
        }

        .travellers {
            top: 254px !important;
            left: 540px !important;
            z-index: 10;
        }  
    }
    .search-card{
        .s-label{
            font-size: 12px !important;
        }
        .departure{
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        .to{
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        .from{
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        .fromDropdown {
            top: 365px !important;
            left: 65px !important;
        }

        .toDropdown {
            top: 365px !important;
            left: 173px !important;
        }

        .travellers {
            top: 365px !important;
            left: 450px !important;
            z-index: 10;
        }
    }
    .mobile-nav{
        display: none !important;
    }

    .lg-device{
        display: unset !important;
    }

    .lg-device-block{
        display: block !important;
    }

    .desktop-nav{
        display: unset !important;
    }

    .mobile{
        display: unset !important;
    }

    .desktop{
        display: none !important;
    }

    .search-card{
        .s-label{
            font-size: 12px !important;
        }
    }

    @for $i from 0 through 20 {
        .lg-pe-#{$i} {
            padding-right: #{$i * 0.5}rem !important;
        }
        .lg-ps-#{$i} {
            padding-left: #{$i * 0.5}rem !important;
        }
        .lg-pt-#{$i} {
            padding-top: #{$i * 0.5}rem !important;
        }
        .lg-pb-#{$i} {
            padding-bottom: #{$i * 0.5}rem !important;
        }


        .lg-me-#{$i} {
            margin-right: #{$i * 0.5}rem !important;
        }
        .lg-ms-#{$i} {
            margin-left: #{$i * 0.5}rem !important;
        }
        .lg-mt-#{$i} {
            margin-top: #{$i * 0.5}rem !important;
        }
        .lg-mb-#{$i} {
            margin-bottom: #{$i * 0.5}rem !important;
        }


        .lg-me-n#{$i} {
            margin-right: -#{$i * 0.5}rem !important;
        }
        .lg-ms-n#{$i} {
            margin-left: -#{$i * 0.5}rem !important;
        }
        .lg-mt-n#{$i} {
            margin-top: -#{$i * 0.5}rem !important;
        }
        .lg-mb-n#{$i} {
            margin-bottom: -#{$i * 0.5}rem !important;
        }

        .lg-px-#{$i} {
            padding-right: #{$i * 0.5}rem !important;
            padding-left: #{$i * 0.5}rem !important;
        }
        .lg-py-#{$i} {
            padding-top: #{$i * 0.5}rem !important;
            padding-bottom: #{$i * 0.5}rem !important;
        }

        .lg-p-#{$i} {
            padding: #{$i * 0.5}rem !important;
        }

        .lg-m-#{$i} {
            margin: #{$i * 0.5}rem !important;
        }

        .lg-m-n#{$i} {
            margin: #{$i * 0.5}rem !important;
        }

        .lg-mx-#{$i} {
            margin-right: #{$i * 0.5}rem !important;
            margin-left: #{$i * 0.5}rem !important;
        }
        .lg-my-#{$i} {
            margin-top: #{$i * 0.5}rem !important;
            margin-bottom: #{$i * 0.5}rem !important;
        }

        .lg-mx-n#{$i} {
            margin-right: -#{$i * 0.5}rem !important;
            margin-left: -#{$i * 0.5}rem !important;
        }
        .lg-my-n#{$i} {
            margin-top: -#{$i * 0.5}rem !important;
            margin-bottom: -#{$i * 0.5}rem !important;
        }
    }
 }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1599.98px) { 
    .mobile-nav{
        display: none !important;
    }

    .xl-device{
        display: unset !important;
    }

    .xl-device-block{
        display: block !important;
    }

    .desktop-nav{
        display: unset !important;
    }

    .mobile{
        display: none !important;
    }

    .desktop{
        display: unset !important;
    }
 }


 @media (min-width: 1600px) and (max-width: 1799.98px) {
    .mobile-nav{
        display: none !important;
    }

    .xl-device{
        display: unset !important;
    }

    .xl-device-block{
        display: block !important;
    }

    .desktop-nav{
        display: unset !important;
    }

    .mobile{
        display: none !important;
    }

    .desktop{
        display: unset !important;
    }

    .pdfr .pdfr-slider{ 
        .slick-list .slick-track {
            padding-left: 200px;
        }
    }

    .home-logo-fixed{
        left: 50px !important;
    }
 }

 @media (min-width: 1800px) {
    .mobile-nav{
        display: none !important;
    }

    .xl-device{
        display: unset !important;
    }

    .xl-device-block{
        display: block !important;
    }

    .desktop-nav{
        display: unset !important;
    }

    .mobile{
        display: none !important;
    }

    .desktop{
        display: unset !important;
    }

    .pdfr .pdfr-slider{ 
        .slick-list .slick-track {
            padding-left: 200px;
        }
    }

    .home-logo-fixed{
        left: 50px !important;
    }
    .main-container{
        padding-left: 15% !important;
        padding-right: 15% !important;
    }
 }